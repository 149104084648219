// LeadForm.js
import { useState } from "react";
import { handleZapier } from "../../util/zapier";
import { useRouter } from "../../util/router";
import { apiRequest } from "../../util/util";

const LeadForm = ({
  zapTags,
  afterUrl,
  pdfAsset,          // URL for the PDF attachment (must be defined)
  emailContent,      // HTML string for email body
  emailSubject       // Email subject
}) => {
  const router = useRouter();
  const [checked, setChecked] = useState(false);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [cell, setCell] = useState("");

  async function postZap(email, cell, first, last, tags, afterUrl) {
    try {
      const response = await fetch(pdfAsset);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64File = reader.result.split(',')[1];
        const emailData = {
          message: emailContent,
          email: email,
          method: "Email",
          subject: emailSubject,
          attachments: [{
            content: base64File,
            filename: pdfAsset.split("/").pop(), // Extracts filename from URL
            type: "application/pdf",
            disposition: "attachment"
          }],
          category: "info"
        };
        const emailResponse = await apiRequest("send-message", "POST", emailData);
        console.log("Email sent successfully", emailResponse);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error sending email: ", error);
    }
    await handleZapier("createLead", {
      email: email,
      phone: cell,
      firstName: first,
      lastName: last,
      tags: tags
    });
    router.push(afterUrl);
  }

  return (
    <div className="p-4">
      <div className="space-y-6">
        <div className="border-b border-gray-900/10 pb-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Getting Started</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            We just need a little bit of information first.
          </p>
          <div className="mt-4 grid grid-cols-1 gap-y-8">
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
                First name
              </label>
              <div className="mt-1">
                <input
                  id="first-name"
                  name="first-name"
                  value={first}
                  onChange={(e) => setFirst(e.target.value)}
                  type="text"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-900">
                Last name
              </label>
              <div className="mt-1">
                <input
                  id="last-name"
                  value={last}
                  onChange={(e) => setLast(e.target.value)}
                  name="last-name"
                  type="text"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                Cell Phone Number
              </label>
              <div className="mt-1">
                <input
                  id="phone"
                  name="phone"
                  value={cell}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');
                    setCell(parseInt(value));
                  }}
                  type="tel"
                  autoComplete="tel"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-900/10 pb-6">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(!checked)}
              className="mr-2"
            />
            <span className="text-xs text-gray-400">
              By submitting this form you agree to receive personalized marketing communications in the form of email or SMS. You also agree to the collection of the information provided above.
            </span>
          </label>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-center">
        <button
          disabled={!checked || (cell === "" || first === "" || last === "" || email === "")}
          onClick={() => postZap(email, cell, first, last, zapTags, afterUrl)}
          className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default LeadForm;
